import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Run from '../components/Run';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;
  const teams = props.data.allMarkdownRemark.edges;

  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="an interactive, multimedia exhibit."
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container">
          <h1>Talking Xbits: Morocco</h1>
          <p>
          An interactive, multimedia exhibit. <br></br>
          </p>
        </div>
      </div>

      <div className="container pt-2">
        <Run button />

      <ol className="pt-6 col-8 col-md-4 mb-2">
      <h1>How It Works</h1>

        <li>Open the camera app on your mobile device, and point it at the QR Code.</li>
        <li>Don’t take a picture! Just let the camera read the QR Code.</li>
        <li>Tap on the notification from the top.</li>
        <li>Listen to the photographer talk about the image. You don’t have to keep pointing the device at the photograph when you do this!</li>
      </ol>

      </div>


      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Meet The Team</h1>
              <p>
                Jackie Spinner and Yad Konrad first collaborated at the American University of Iraq in Sulaimani in 2010.
                 Spinner was the founder and faculty advisor of the AUIS Voice,
                  Iraq’s first independent student newspaper. Konrad was the paper’s first student design editor. He graduated in 2013 with a degree in Computer Science.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="container pb-6">
        <div className="row">
          {teams.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
              <div className="team card-two">
                <div className="card-header">
                  <div className="card-header-left">
                    {edge.node.frontmatter.image && (
                      <div className="card-image">
                        <img
                          alt={edge.node.frontmatter.title}
                          className="img-fluid mb-2"
                          src={edge.node.frontmatter.image}
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-right lowername">
                    <h2 className="card-title">{edge.node.frontmatter.title}</h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{edge.node.frontmatter.jobtitle}</strong>
                      </li>

                    </ul>
                  </div>
                </div>
                {edge.node.frontmatter.biotitle}
              </div>
            </div>
          ))}
        </div>

        <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/714390667%3Fsecret_token%3Ds-QpQQf&color=%23ccc4c4&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>

        <div className="">

        <div className="">
          <div className="row">
            <div className="col-12">
            <h1>Meet The Artists</h1>
              <p>
              Photographers:  <a href="https://www.imdb.com/name/nm1485865/" >Hakim Belabbes</a> (MFA 99), Niki Cocorelis (BA 20), Hannah Faris (BA 20), Zackery Kearns (BA 20), Halie Parkinson (BA 19), Anjali Paul (BA 19), Caroline Sheets (BA 20), Colton Weiss (BA 19)
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>




      <div className="pb-6 page-home-lower">
        <div className="container ">
          <div className="row">
            <div className="col-12 col-md-4 mb-1">
            <h1>About The Exhibit</h1>

                <p>
                Talking Xbits is an entrepreneurial-research project between Columbia College Chicago and Congruent AI.
                </p>
                The photographs and video featured in the exhibit were captured in January 2018 during a documentary journalism course to Morocco taught by filmmaker and Columbia College Chicago MFA graduate Hakim Belabbes and former broadcast journalism professor Yolanda Joe. Students filmed on location in Boujad, Morocco. The Columbia College Chicago students worked alongside young Moroccan filmmakers from the Sahara Lab.
                The exhibit uses machine learning technology to enable viewers to listen to audio stories from the student and faculty creators and to view video shorts on a website accessible from a smartphone or other handheld mobile device.

            </div>


          </div>
        </div>
      </div>


      {/* <div className="pb-6 page-home-lower">
        <div className="container ">
          <div className="row">


          </div>
        </div>
      </div> */}



    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/team/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            image
            jobtitle
            biotitle
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;


export default Home;
